<template>
	<div class="setting-box">
		<ul>
			
			<li>
				<div class="left">
					<div class="img-box" style="cursor: pointer;">
						1
					</div>
					
					<div class="title flex" v-if="!address">
						<span>Connect your wallet</span>
					</div>
					
					<div class="blind-box" v-if="address ">{{address | addressF}}</div>
				</div>
				<div class="right">
					<div class="btn-box-ok " v-if="address">
					</div>
					<template v-else>
						<div class="btn-box" v-if="address" @click="disconnect">{{$Vsetting("已连接")}}</div>
						<div class="btn-box active" v-else @click="goLogin">{{$Vsetting("连接wallet")}}</div>
					</template>
				</div>
			</li>
			
			<p class="line"></p>
			<li>
				
				<div class="left">
					<div class="img-box" style="cursor: pointer;" @click="toPage('https://twitter.com/wod_global')">
						2
					</div>
					<div class="title flex"  v-if="!tw_name">
						<span>Follow</span>
						<a href="https://twitter.com/wod_global" target="_blank">@wod_global</a>
						<span>on twitter</span>
					</div>
					
					<div class="blind-box"  v-if="address && tw_name">{{tw_name}}</div> 
				</div>
				<div class="right">
					<div class="btn-box-ok " v-if="address && (status == 1 || status == 3)">
					</div>
					<template v-else>
						<div class="btn-box" :class="{active: address}" @click="twAuth" v-if="isVTw == false">{{$Vsetting("连接twitte_6af5")}}</div>
						<div class="btn-box active" @click="twFollowed" v-if="isVTw == true">{{$Vsetting("关注BP-FLA_3aea")}}</div>
					</template>
				</div>
			</li>
			<p class="line"></p>
			<li>
				<div class="left">
					<div class="img-box" style="cursor: pointer;" @click="toPage('https://discord.gg/CscJeyvybG')">
						3
					</div>
					
					<div class="title flex" v-if="!tw_name">
						<span>Join</span>
						<a href="https://discord.gg/CscJeyvybG" target="_blank">BP-FLAC</a>
						<span>discord</span>
					</div>
					
					<div class="blind-box" v-if="address && dc_name">{{dc_name}}</div>
				</div>
				<div class="right">
					<div class="btn-box-ok " v-if="address && (status == 2 || status == 3)">
					</div>
					<template v-else>
						<div class="btn-box" :class="{active: address && tw_name}" @click="dcAuth()" v-if="isVDc == false">{{$Vsetting("连接discor_13c8")}}</div>
						<div class="btn-box active" @click="dcFollowed()" v-if="isVDc == true">{{$Vsetting("加入BP-FLA_7246")}}</div>
					</template>
				</div>
			</li>
			
			
			
		</ul>
	</div>
</template>

<script>
	import { setAddress, getAddress } from '@/utils/auth.js';
	import { enable, disconnect } from "@/web3utils/base/index.js";
	import { twAuth, twFollowed, dcAuth, dcFollowed, checkStatus, userInfo } from '../../utils/twitter.api.js';
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VsettingMessages from "./setting.messages.json";
  const $defaultObject = {
		name: 'setting',
		filters: {
			addressF(v) {
				if (!v) {
					return "";
				}
				return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, "$1……$2");
			},
		},
		data() {
			return {
				dc_name: '',
				tw_name: '',
				status: 0,
				isVTw: false,
				isVDc: false,
				routeQ: '',
			}
		},
		computed: {
			...mapState(['locales', 'lang', 'address']),
		},
		watch: {
			address(newValue, oldValue) {
				setTimeout(() => {
					this.init()
				}, 200)
			}
		},
		mounted() {
			this.routeQ = this.$route.query.verify;
			const routeName = this.$route.query.name;

			if (this.routeQ == 'tw_repeat') {
				this.$nextTick(() => {
					this.$notification.error({
						message: 'Error',
						description: 'This twitter has been connected with another address',
					});
				})
			}
			if (this.routeQ == 'dc_repeat') {
				this.$nextTick(() => {
					this.$notification.error({
						message: 'Error',
						description: 'This discord has been connected with another address',
					});
				})
			}
			if (this.routeQ == 'twitter') {
				this.isVTw = true;
				this.tw_name = routeName || ''
			}
			if (this.routeQ == 'discord') {
				this.isVDc = true;
				this.dc_name = routeName || ''
			}
			this.init()

		},
		methods: {
			init() {
				if (!this.address) return
				this.checkStatus()
				this.userInfo()
			},
			disconnect() {
				setAddress('')
				this.$store.commit('SET_ADDRESS', '');
				disconnect()
				window.location.reload();
			},

			toPage(link) {
				if (!link) return
				window.open(link)
			},

			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					console.log("Wallet not logged in");
					return;
				}
			},
			//验证状态
			async checkStatus() {
				const { success, data: { url }, code } = await checkStatus({
					addr: this.address,
				});
				window.open(url)
				// if (success) {
				// 	window.open(data)

				// }
			},

			//推特
			async twAuth() {
				if (!this.address) return
				const { success, data: { url }, code } = await twAuth({
					addr: this.address,
				});
				this.isVTw = true;
				window.open(url);
			},
			async twFollowed() {
				const { success, code } = await twFollowed({
					addr: this.address,
				});
				if (code == 200) {
					this.init();
				}
			},
			//Dc 
			async dcAuth() {
				if (!this.tw_name) return
				const { success, data: { url }, code } = await dcAuth({
					addr: this.address,
				});
				this.isVDc = true;
				window.open(url);
			},
			async dcFollowed() {
				const { success, code } = await dcFollowed({
					addr: this.address,
				});
				if (code == 200) {
					this.init();
				}
			},
			async checkStatus() {
				const { success, data: { status }, code } = await checkStatus({
					addr: this.address,
				})
				this.status = status
			},
			async userInfo() {
				const { success, data: { dc_name, tw_name }, code } = await userInfo({
					addr: this.address,
				});
				this.dc_name = dc_name
				this.tw_name = tw_name
			},
		},

	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $Vsetting(key,origin){
      const messages = $VsettingMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $Vsetting(key,origin){
      const messages = $VsettingMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
	.setting-box {
		width: 638px;

		ul {
			padding: 20px 0;

			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 27px 0;

				.left {
					display: flex;
					align-items: center;

					.img-box {
						width: 78px;
						height: 66px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/settingNumIcon.png') no-repeat;
						background-size: 100% 100%;

						font-size: 24px;
						font-weight: 700;
 
						color: rgba(255, 255, 255, 0.6);
					}

					.title {
						padding-left: 24px;

						span,
						a {
							
							font-size: 24px;
							font-weight: 700;
							line-height: 24px;
							letter-spacing: 0em;
							text-align: left;
							color: rgba(255, 255, 255, 0.6);

						}

						a {
							padding: 0 8px;
							background: var(--2, linear-gradient(270deg, #203DFD 0%, #34C5F3 100%));
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}
					.blind-box{
						padding-left: 20px;
						
						font-size: 24px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0em;
						text-align: left;
						color: rgba(255, 255, 255, 0.6);
					}
					// a {
					// 	padding-left: 20px;
					// 	font-size: 20px;
					// 	text-decoration: underline;
					// 	text-underline-offset: 5px;
					// 	cursor: pointer;
					// }
				}

				.right {
					.active {
						background: linear-gradient(90deg, #2D41FA 0%, #4CE4F9 100%) !important;
					}

					.btn-box-ok {
						width: 40px;
						height: 40px;
						background: url(https://wami-wod.oss-cn-beijing.aliyuncs.com/img/settingNumOk.png) no-repeat;
						background-size: 100% 100%;

					}

					.btn-box {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 151px;
						height: 33px;
						border-radius: 16px;
						background: #1C1D1F;
						font-size: 16px;
						color: #fff;
					}
				}

				&:hover {
					.left {
						.title span {
							color: #fff !important;
						}
					}
					.right {
						.active {
							background: linear-gradient(90deg, #0016DD 0%, #4C7CF9 100%) !important;
						}
					}
				}
			}


			.line {
				height: 1px;
				width: 100%;
				background: rgba(255, 255, 255, 0.2);
				margin: 0;
				padding: 0;
			}

			.bbtn-box {
				padding: 10px 20% 0;

				.ant-btn-primary {
					width: 100%;
					height: 100%;
					font-size: 16px;
					color: #fff;
					font-weight: 400;
					padding: 7px 0;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;
				}
			}
		}

		@screen md {
			width: 333px;

			ul {
				padding: 0;

				li {
					padding: 14px 0;
					.left {
						.img-box {
							width: 28px;
							height: 34px;
							font-size: 16px;
							color: rgba(255, 255, 255, 1);
						}
						.title{
							padding-left: 12px;
							display: flex;
							flex-wrap: wrap;
							word-wrap: break-word; /* 或者使用 normal */
							a, span {
								font-size: 14px ;
							}
						}
						.blind-box{
							padding-left: 20px;
							
							font-size: 14px;
							font-weight: 400;
						}
					}

					.right {
						.btn-box-ok {
							width: 20px;
							height: 20px;
						
						}
						.btn-box {
							width: 78px;
							height: 17px;
							font-size: 12px;
							padding: 10px 18px;
							border-radius: 10px;
						}
						
					}
				}
			}

			.bbtn-box {
				padding: 2px 20% 0;

				.ant-btn-primary {
					width: 100%;
					height: 100%;
					font-size: 11px;
					color: #fff;
					font-weight: 400;
					padding: 3px 0;
					background: linear-gradient(to right, #203dfc, #33c4f2);
					border: none;
				}
			}
		}
	}
</style>