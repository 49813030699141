<template>
	<div class="faucet">
		<Header></Header>
		<div class="faucet-box md:px-12">
			<div class="supply-title">
				<div class="title">
					<h1 class="flex" @click="$router.go(-1)">
						<a-icon type="left-circle" style="color: #fff;"/>
						<span>Settings</span>
					</h1>
					<h2 >Start your journey with BP-FLAC!</h2>
				</div>
			</div>
			<div class="faucetCard-box">
				<setting></setting>
				<div class="img-box">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/settingRightBg.png" alt="" srcset="" />
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import Footer from '@/components/Footer.vue'
	import setting from '@/components/setting/setting.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Faucet',
		components: {
			Header,
			Breadcrumb,
			Footer,
			setting
		},
		data() {
			return {
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},

					{
						path: '/Setting',
						breadcrumbName: 'Setting',
					},
				],
			}
		},
		computed: {
			...mapState(['isConnect', 'address']),
		},
		mounted() {
			// 0x09128f1081771c9ad474363ff9cf3845977e39a2&code=S0tCRkxkX1I3OGZWZ1VhZVJmZS1zMldXUWh1aUJ3Mk1JUU9hREhQVWFMQndoOjE3MDIwMzE3MjYxNDA6MTowOmFjOjE
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.faucet {
		position: relative;
		background-color: #000000;
		color: #ffffff;
		overflow: hidden;

		.faucet-box {
			padding: 80px 75px 30px;
			@media screen and (max-width: 1440px) {
				padding: 50px 75px 0;
			}
		}


		.supply-title {
			.title {
			
				@screen md {
					display: block;
				}

				h1 {
					margin: 0;
					.anticon-left-circle{
						font-size: 24px;
					}
					span{
						/* margin-bottom:7px; */
						margin-left: 10px;
						color: rgba(255, 255, 255, 0.60);
						font-size: 24px;
						font-style: normal;
						font-weight: 590;
						line-height: 24px; /* 100% */
						font-variant: all-small-caps;
					}
					@screen md { 
						
						.anticon-left-circle{
							font-size: 20px;
						}
						font-size: 20px;
						text-align: left;
					}
				}
				h2{
					padding-top: 10px;
					color: #FFF;
					
					font-size: 32px;
					font-style: normal;
					font-weight: 860;
					line-height: 32px; /* 100% */
					@screen md {
						display: none;
						font-size: 18px;
						text-align: left;
					}
				}

				span {
					font-size: 64px;
					font-weight: 800;
					/* background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent; */
					display: inline-block;
					color: rgba($color: #fff, $alpha: .6);
					margin: 0;

					@screen md {
						display: inline-block;
						font-size: 30px;
						text-align: center;
					}
				}
			}

			p {
				text-align: center;
				color: rgba(255, 255, 255, 0.60);
				font-size: 20px;
				font-weight: 700;
				font-style: normal;
				line-height: 32.5px;
				/* 162.5% */
				letter-spacing: 2px;
				margin: 0;

				@screen md {
					font-size: 10px;
					line-height: 18px;
					letter-spacing: 0.1px;



				}
			}
		}

		.faucetCard-box {
			min-width: 1440px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media screen and (max-width: 1440px) {
				min-width: 1200px;
			}

			.setting-box {
				flex: 1;
				margin-right: 160px;
				@media screen and (max-width: 1440px) {
					margin-right: 100px;
				}
			}

			.img-box {
				max-width: 580px;
				max-height: 580px;

				img {
					width: 100%;
					height: 100%;
				}
				@media screen and (max-width: 1440px) {
					max-width: 500px;
					max-height: 500px;
				}
			}


			@screen md {
				min-width: unset;
				display: flex;
				flex-direction: column-reverse;
				.setting-box {
					flex: 1;
					margin-right: unset;
				}
				.img-box {
					width: 240px;
					height: 240px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}



	}
</style>